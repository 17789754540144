'use strict';

/**
 * Initialize domain for cross-subdomain communication and typeahead handler
 */
function initialize() {
    // set domain so results can be retrieved from netgear.com without blocked XSS
    document.domain = 'netgear.com';

    $('.js-typeahead').typeahead({
        cancelButton: false,
        dynamic: true,
        highlight: false,
        source: {
            suggestions: {
                filter: false,
                ajax: {
                    type: 'GET',
                    url: 'https://www.netgear.com/searchdynnav_handler.ashx?callback=?&mode=autocomplete&client=&site=&q={{query}}',
                    callback: {
                        done: function (data) {
                            // suggestions response is an array of objects with the suggested string as 'text' property
                            var suggestionArray = data.map(function (item) {
                                return item.text;
                            });
                            return suggestionArray;
                        }
                    }
                }
            }
        }
    });

    $('body').on('click', '.typeahead__item', function () {
        $(this).parents('form:first').submit();
    });
}

module.exports = {
    initialize: initialize
};
