'use strict';

var searchBar = require('../search/searchbar');

/**
 * Initializing Events
 */
function initialize() {
    searchBar.initialize();
}

module.exports = {
    initialize: initialize
};
